// react
import React, { createRef, useEffect, useState } from 'react'
// react bootstrap components
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Pagination from 'react-bootstrap/Pagination'
import Table from 'react-bootstrap/Table'
// external modules
import axios from 'axios'
import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'
// ui
import 'bootstrap/dist/css/bootstrap.css'
import 'react-day-picker/lib/style.css'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'
import { FcDownload, FcSynchronize } from 'react-icons/fc'
import { RiFileExcel2Line } from 'react-icons/ri'
import { VscFilePdf } from 'react-icons/vsc'
import Swal from 'sweetalert2'
// internal modules
import { GlobalDispatchContext } from 'context/global_context_provider'
import Layout from 'components/layout'
// internal functions
import {
  customDate,
  compareJsDateToDbDate
} from 'tools/date'
import {
  compareStation,
  compareUnit,
  compareCycle,
  compareTimestamp,
  compareOwner,
  compareString,
  compareProjectionId
} from 'tools/sort'
import { splitArray } from 'tools/util'
// etc
import DepletionStationOptions from 'components/options/station/depletion'
import DepletionUnitOptions from 'components/options/unit/depletion'

const ReportArchivePage = (props) => {
  const dispatch = React.useContext(GlobalDispatchContext)
  useEffect(() => {
    console.log('first load')
  }, [])
  const [owner, setOwner] = useState('')
  const [projectionId, setProjectionId] = useState('')
  const [day, setDay] = useState('')
  const [station, setStation] = useState('')
  const [unit, setUnit] = useState('')
  const [cycle, setCycle] = useState('')
  const [cookies, setCookie] = useCookies()
  useEffect(() => {
    if (!props.personalView) {
      console.log('clearing owner')
      setOwner('')
    }
  }, [props.personalView])
  const emailProfile = (
    (props.data)
      ? props.data.profile.email
      : null
  )
  const items = (props.data) ? props.data.reports : null
  const [sortConfig, setSortConfig] = React.useState('')
  const fullName = (
    (props.data)
      ? props.data.profile.familyName +
        ', ' +
        props.data.profile.givenName
      : ''
  )
  const properName = (
    (props.data)
      ? props.data.profile.givenName +
        ' ' +
        props.data.profile.familyName
      : ''
  )
  console.log(properName)

  let sorted = React.useMemo(() => {
    let sorte = null
    if (!items) {
      return sorte
    } else if (!sortConfig) {
      sorte = (
        items
          .sort((a, b) => compareTimestamp(a, b, false))
          .sort((a, b) => compareCycle(a, b, false))
          .sort(compareUnit)
          .sort(compareStation)
      )
    } else {
      const compareFunctions = {
        station: compareStation,
        unit: compareUnit,
        cycle: compareCycle,
        owner: compareOwner,
        timestamp: compareTimestamp,
        projectionId: compareProjectionId
      }
      const compareFunction = compareFunctions[sortConfig[0]]
      sorte = (
        items
          .sort((a, b) => compareTimestamp(a, b, false))
          .sort(compareCycle)
          .sort(compareUnit)
          .sort(compareStation)
          .sort((a, b) => compareFunction(a, b, sortConfig[1]))
      )
    }
    if (station) {
      sorte = sorte.filter(item => item.station.S === station)
    }
    if (unit) {
      sorte = sorte.filter(item => item.unit.N === unit)
    }
    if (cycle) {
      sorte = sorte.filter(item => item.cycle.N === cycle)
    }
    if (owner) {
      sorte = sorte.filter(item => (
        item.owner.S.toLowerCase().includes(owner.toLowerCase())
      ))
    }
    if (day) {
      sorte = sorte.filter(item => (
        compareJsDateToDbDate(day, item.date.M)
      ))
    }
    if (projectionId) {
      sorte = sorte.filter(item => (
        item.projection_id.S.toLowerCase().includes(projectionId.toLowerCase())
      ))
    }
    if (props.personalView && props.data) {
      sorte = sorte.filter(item => (item.owner.S === fullName))
    }
    return sorte
  }, [
    station,
    unit,
    cycle,
    items,
    sortConfig,
    owner,
    day,
    projectionId,
    props.personalView
  ])

  const [selection, setSelection] = useState(null)
  const getSelectedReportKeys = () => (
    sorted
      .filter((x, i) => selection[i])
      .map(x => x.id.S)
  )
  const getSelectedReports = () => (
    sorted
      .filter((x, i) => selection[i])
  )
  const toggleSelection = (index) => {
    const copy = [...selection]
    const nSelected = copy.filter(x => x).length
    copy[index] = !copy[index]
    console.log('toggling selection ' + index)
    if (nSelected < 5 || !copy[index]){
      setSelection(copy)
    }
  }
  const unselect = () => {
    console.log('clearing selections')
    setSelection([...selection].map(x => false))
  }
  useEffect(() => {
    if (sorted) {
      console.log('attaching selections to sorted')
      setSelection(sorted.map(x => false))
    }
  }, [sorted])
  const requestSort = key => {
    console.log('requesting sort')
    unselect()
    console.log(sortConfig)
    console.log(key)
    if (key === sortConfig[0] && !sortConfig[1]) {
      setSortConfig('')
    } else {
      const ascending = (
        !sortConfig ||
        sortConfig[0] !== key ||
        (sortConfig[0] === key && !sortConfig[1])
      )
      setSortConfig([key, ascending])
    }
  }
  const [page, setPage] = useState(0)
  const nPages = (
    (sorted)
      ? Math.ceil(sorted.length / 20)
      : null
  )
  useEffect(() => {
    document.onkeydown = (e) => {
      if (e.key === 'ArrowRight' && nPages && page < nPages - 1) {
        setPage(page + 1)
      } else if (e.key === 'ArrowLeft' && nPages && page > 0) {
        setPage(page - 1)
      }
    }
  })
  const thisPagination = (
    (sorted && sorted.length > 20)
      ? (
        <Pagination>
          {
            [...Array(nPages).keys()].map(i => (
              <Pagination.Item
                key={'page-item-' + i}
                active={(i === page)}
                onClick={() => {
                  console.log('changing page')
                  setPage(i)
                }}
              >
                {i + 1}
              </Pagination.Item>
            ))
          }
        </Pagination>
        )
      : null
  )
  const downloadPdfUrl = (
    'https://api.demo.bwnuclear.ai/download/mco/reports/'
  )
  const downloadXlUrl = (
    'https://api.demo.bwnuclear.ai/download/mco/reporttables/'
  )
  const tableBody = (
    (items && sorted.length > 0 && selection)
      ? (splitArray(sorted, 20)[page]
          .map((item, index) => (
            <tr key={'report-' + index.toString()}>
              <td>
                <Form.Check
                  checked={selection[page * 20 + index]}
                  onChange={() => toggleSelection(page * 20 + index)}
                />
              </td>
              <td>{props.appInfo.stations[item.station.S].name}</td>
              <td className='text-center'>{item.unit.N}</td>
              <td className='text-center'>{item.cycle.N}</td>
              <td>{item.projection_id.S}</td>
              <td>{item.owner.S}</td>
              <td>{customDate(item.date.M)}</td>
              <td>
                <Container className='justify-content-center'>
                  <Row xs='auto' className='justify-content-center'>
                    <Col xs='auto'>
                      <a
                        href={downloadPdfUrl + item.id.S}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <VscFilePdf />
                      </a>
                    </Col>
                    <Col>
                      <a
                        href={downloadXlUrl + item.id.S}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <RiFileExcel2Line />
                      </a>
                    </Col>
                  </Row>
                </Container>
              </td>
            </tr>
          )))
      : <></>
  )
  console.log('day:')
  console.log(day)
  const deleteReports = async () => {
    const url = (
      'https://api.demo.bwnuclear.ai/delete/mco/report'
    )
    const items = getSelectedReportKeys()
    unselect()
    if (items.length === 1 || items.length === 2 || items.length === 3 || items.length === 4 || items.length === 5) {
      const deleteCandidate = getSelectedReports()[0]
      if (deleteCandidate.owner.S !== fullName) {
        dispatch({
          type: 'addAlert',
          toAdd: {
            id: uuidv4(),
            type: 'warningText',
            text: `${properName} please only delete files that you own`
          }
        })
        return
      }
      const key = items.join(',')
      const params = {
        params: { id: key }, withCredentials: true }
      let confirmDelete = false
      await Swal.fire({
        title: 'Are you sure you want to delete this report?',
        text: `${properName} you will not be able to undo this action!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          confirmDelete = true
        }
      })
      if (confirmDelete) {
        const deleteResult = await axios.get(url, params)
        console.log(deleteResult)
        dispatch({
          type: 'addAlert',
          toAdd: {
            id: uuidv4(),
            type: 'text',
            text: `${properName} delete completed.`
          }
        })
      }
      props.fetch()
    } else if (items.length === 0) {
      console.log('trigger dispatch')
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'warningText',
          text: `${properName} no file is selected`
        }
      })
    } else {
      console.log('Delete files one at a time')
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'warningText',
          text: `${properName} you may delete up to 5 reports at once`
        }
      })
    }
  }
  console.log(props.data)

  const sortButton = key => {
    if (sortConfig[0] !== key) {
      return (
        <FaSort
          type='button'
          onClick={() => requestSort(key)}
        />
      )
    } else if (sortConfig[1]) {
      return (
        <FaSortUp
          type='button'
          onClick={() => requestSort(key)}
        />
      )
    } else {
      return (
        <FaSortDown
          type='button'
          onClick={() => requestSort(key)}
        />
      )
    }
  }

  return (
    <>
      <Container>
        <Row className='mb-3'>
          <h1
            className='featurette-heading'
            style={{ fontWeight: '100' }}
          >
            Report Archive
          </h1>
        </Row>
        <>
          <Card
            style={{
              fontWeight: '100',
              backgroundColor: '#caccd1',
              borderRadius: '5px',
              boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
            }}
            className='lead mb-3'
          >
            <Card.Header as='lead'>
              Filter Table Entries
            </Card.Header>
            <Card.Body>
              <Form className='justify-content-around'>
                <Row className='mb-3'>
                  <Col xs='auto'>
                    {
                      (props.personalView)
                        ? (
                          <Form.Group>
                            <Form.Label>Owner</Form.Label>
                            <Form.Control
                              type='text'
                              size='md'
                              value={fullName}
                              disabled
                            />
                          </Form.Group>
                          )
                        : (
                          <Form.Group>
                            <Form.Label>Owner</Form.Label>
                            <Form.Control
                              type='text'
                              size='md'
                              value={owner}
                              onChange={(e) => {
                                setOwner(e.target.value)
                              }}
                              placeholder='LAST, FIRST'
                            />
                          </Form.Group>
                          )
                    }
                  </Col>
                  <Col xs='auto'>
                    <Form.Group>
                      <Form.Label>Projection ID</Form.Label>
                      <Form.Control
                        type='text'
                        size='md'
                        onChange={(e) => {
                          console.log('changing projection id')
                          setProjectionId(e.target.value)
                        }}
                        placeholder='YYMMDD-XXXXXX'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col xs='auto'>
                    <Form.Group>
                      <Form.Label> Station </Form.Label>
                      <Form.Select
                        size='md'
                        value={station}
                        onChange={e => {
                          setUnit('')
                          setStation(e.target.value)
                        }}
                      >
                        <option value=''> </option>
                        <DepletionStationOptions />
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs='auto'>
                    <Form.Group>
                      <Form.Label> Unit </Form.Label>
                      <Form.Select
                        size='md'
                        value={unit}
                        onChange={e => setUnit(e.target.value)}
                      >
                        {
                          (station)
                            ? <option value=''> </option>
                            : null
                        }
                        <DepletionUnitOptions station={station} />
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs='auto'>
                    <Form.Group>
                      <Form.Label> Cycle </Form.Label>
                      <Form.Control
                        size='md'
                        type='number'
                        min='0'
                        max='1000'
                        onChange={e => setCycle(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col xs='auto'>
                    <Form.Group>
                      <Form.Label>Date</Form.Label>
                      <div>
                        <DayPickerInput
                          onDayChange={(day, mods, input) => {
                            console.log('changing day input')
                            setDay(day)
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col xs='auto'>
                    <Form.Switch
                      type='switch'
                      label='Personal View'
                      title='Toggle between Personal and Company View'
                      checked={props.personalView}
                      onChange={props.togglePersonalView}
                    />
                  </Col>
                  <br /><br />
                  <Row className='mb-3'>
                    <Col xs='10'>
                      <Button
                        size='sm'
                        type='button'
                        title='Refresh Report Data'
                        variant='outline-primary'
                        style={{ borderRadius: '25px' }}
                        onClick={() => {
                          unselect()
                          props.fetch()
                        }}
                      >
                        <FcSynchronize size={24} />
                        &nbsp;
                        Refresh Data
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </>
        <Row className='mb-3'>
          <Col>
            <Table striped bordered hover style={{ boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)' }}>
              <thead>
                <tr>
                  <th />
                  <th>
                    Station
                    {sortButton('station')}
                  </th>
                  <th className='text-center'>
                    Unit
                    {sortButton('unit')}
                  </th>
                  <th className='text-center'>
                    Cycle
                    {sortButton('cycle')}
                  </th>
                  <th>
                    Projection ID
                    {sortButton('projectionId')}
                  </th>
                  <th>
                    Owner
                    {sortButton('owner')}
                  </th>
                  <th>
                    Upload (EST)
                    {sortButton('timestamp')}
                  </th>
                  <th>
                    <Container>
                      <Row xs='auto' className='justify-content-center'>
                        <Col>
                          File
                          <FcDownload />
                        </Col>
                      </Row>
                    </Container>
                  </th>
                </tr>
              </thead>
              <tbody>{tableBody}</tbody>
            </Table>
          </Col>
        </Row>
        <Row xs='auto' className='justify-content-center'>
          <Col>
            {thisPagination}
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col>
            <Dropdown drop='up'>
              <Dropdown.Toggle variant='secondary' id='dd-action'>
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={deleteReports}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    </>
  )
}

ReportArchivePage.Layout = Layout

export default ReportArchivePage
